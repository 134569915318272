import { useQuery } from "@tanstack/react-query";
import "./App.css";
import React, { useState } from "react";

function App() {
  const [showModal, setShowModal] = useState(false);

  const { status, data, refetch } = useQuery({
    queryFn: () =>
      fetch("https://ppv-rzemientv.com:8000/api/stream", {
        credentials: "include",
      }).then(async (res) => JSON.parse(await res.text())),
  });

  return (
    <div className="App">
      {!showModal && (
        <>
          <div className="Title">
            Zasubskrybuj nas na{" "}
            <a
              href="https://www.youtube.com/@rzemientv"
              target="_blank"
              rel="noreferrer"
            >
              [YouTube]
            </a>
            , otrzymaj status PARTNER a będziesz mógł oglądać za darmo większość
            transmisji
          </div>
          <div className="MainStreamContainer">
            <div className="StreamContainerShadow"></div>
            <div className="StreamContainer">
              {status === "success" ? (
                !data["is_stream"] ? (
                  <div className="streamText">
                    UPS! w tym momencie nie ma aktywnego streamu...
                  </div>
                ) : !data["permissions"] ? (
                  <div className="streamText">
                    W tym momencie trwa płatny stream, wykorzystaj swój kod
                    dostępu aby móc go obejrzeć!
                    <br />
                    <br /> Jeśli takiego nie posiadasz możesz go zakupić{" "}
                    <a
                      href="https://www.sklep.rzemien.tv/gale-ppv-c-3_296.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      [ Tutaj ]
                    </a>
                  </div>
                ) : (
                  <>
                    <div className="streamErrorText">
                      Nie widzisz obrazu? Odśwież przeglądarkę i zaczekaj 15s
                    </div>
                    <div
                      className="Stream"
                      dangerouslySetInnerHTML={{
                        __html: data["stream"],
                      }}
                    />
                  </>
                )
              ) : (
                <div className="streamText">ładowanie...</div>
              )}
            </div>
          </div>
          <div className="buttonWrapper">
            {status === "success" &&
              data["is_stream"] &&
              !data["permissions"] && (
                <button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className="useCode"
                >
                  Aktywuj kod
                </button>
              )}
            <button
              onClick={() => {
                window.open("https://www.rzemien.tv/kontakt-f-1.html");
              }}
              className="useCode"
            >
              POMOC
            </button>
          </div>
        </>
      )}
      {showModal && (
        <>
          <button
            className="goBack"
            onClick={() => {
              setShowModal(false);
            }}
          ></button>
          <div className="ModalContainerShadow"></div>
          <div className="ModalContainer">
            <div className="ModalTitle">Wpisz swój kod</div>
            <input type="text" placeholder="ABCD-XYZW-1234" id="code" />
            <button
              onClick={() => {
                const code = document.getElementById("code").value;
                const error = document.getElementById("error");
                if (code === "") {
                  error.innerText = "Pole nie może być puste!";
                  return;
                }
                if (code.length > 20) {
                  error.innerText = "Kod może mieć maksymalnie 20 znaków!";
                  return;
                }
                fetch("https://ppv-rzemientv.com:8000/api/activate_code", {
                  method: "POST",
                  credentials: "include",
                  body: JSON.stringify({ code }),
                }).then(async (res) => {
                  let response = await res.text();
                  if (response === "Kod nie istnieje") {
                    error.innerText = response;
                  }

                  response = JSON.parse(response);

                  if (!response["correct"]) {
                    // jak?
                    error.innerText = "Niepoprawny kod";
                  } else {
                    document.cookie = `uuid=${response["uuid"]}`;
                    refetch();
                    setShowModal(false);
                  }
                });
              }}
              className="useCodeModal"
            >
              Aktywuj kod
            </button>
            <div id="error"></div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
